import React from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/layout';
import PageHeading from '~/components/styled/page-heading';
import MemeList from '~/components/meme-list';
import SEO from '~/components/seo';

const CategoryPage = ({ data }) => {
  const memes = data.strapiCategory.memes;
  const seo = {
    title: data.strapiCategory.name,
  };

  return (
    <Layout>
      <SEO seo={seo} />
      <div>
        <PageHeading>{data.strapiCategory.name}</PageHeading>
        <MemeList memes={memes} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CategoryQuery($slug: String!) {
    strapiCategory(slug: { eq: $slug }) {
      name
      memes {
        title
        slug
        id
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.3
            )
          }
        }
      }
    }
  }
`;

export default CategoryPage;
